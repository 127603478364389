import { makeStyles } from '@material-ui/core/styles';
import { WHITE, BLUE_LIGHT } from '@theme_color';
import {
    CreateMargin, CreatePadding, CenterAbsolute, Centering,
} from '@theme_mixins';
import { SECONDARY, GRAY_200, GRAY_100 } from '@src_theme/colors';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflowX: 'hidden',
        ...CreatePadding(0, 0, 0, 0),
    },
    category: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        ...CreateMargin(30, 0, 30, 0),
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: WHITE,
        '@media (min-width: 768px )': {
            backgroundColor: 'transparent',
        },
        '& a img': {
            height: 333,
            objectFit: 'cover',
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
            },
        },
    },
    logo: {
        position: 'absolute',
        zIndex: 99,
        ...CenterAbsolute,
    },
    titleLogo: {
        fontWeight: 'bold',
        fontFamily: 'Playfair Display',
        color: WHITE,
    },
    mobileTopNav: {
        display: 'flex',
        gap: '12px',
        alignItems: 'center',
        paddingTop: 12,
        paddingBottom: 12,
        '& > :nth-last-child(2)': {
            marginLeft: 'auto',
        },
        '& > :last-child': {
            color: SECONDARY,
            height: 'min-content',
        },
    },
    imgLogoMobile: {
        height: 32,
        objectFit: 'cover',
    },
    skeletonWrapper: {
        padding: '0 0 12px 0',
        width: '100%',
        position: 'relative',
        marginTop: '40px',
        '& .logo': {
            width: 100,
            top: 5,
            position: 'absolute',
            zIndex: 99,
            ...CenterAbsolute,
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiGrid-spacing-xs-2': {
                marginTop: 20,
            },
            marginTop: 20,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 40,
        },
    },
    skeleton: {
        marginBottom: '8px',
    },
    divMessage: {
        minHeight: 100,
        width: '100%',
        ...Centering,
    },
    labelCategory: {
        paddingTop: 0,
        paddingBottom: 20,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            paddingBottom: 10,
        },
    },
    contentContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            // marginLeft: '20px',
            // marginRight: 'auto',
            marginTop: 0,
            marginBottom: 0,
        },
        [theme.breakpoints.up('md')]: {
            width: 970,
        },
        [theme.breakpoints.up('lg')]: {
            width: 1170,
        },
    },
    features: {
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
            margin: 0,
            marginTop: 20,
            marginBottom: 10,
        },
    },
    featuresBox: {
        borderRadius: '10px',
        backgroundColor: 'white',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            margin: '0',
            boxShadow: 'unset',
            border: '0',
        },
    },
    contentFeatured: {
        transition: '0.3s',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
    },
    contentMobileFeatured: {
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
        '@media (max-width: 767px )': {
            '& *': {
                fontSize: '2vw',
            },
        },
    },
    footerFeatured: {
        paddingBottom: 0,
        margin: '10px 0',
        '& a': {
            width: '100%',
        },
    },
    imgFeaturedContainer: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 768px )': {
            padding: '0 10px 0 0',
        },
    },
    imgFeatured: {
        width: '100%',
        height: '100%',
        borderRadius: '20px',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '0px',
        },
    },
    imgFeaturedItem: {
        width: '100%',
        backgroundColor: 'transparent',
        height: '100%',
        position: 'relative',
    },
    imgFeaturedContainerMobile: {
        width: '100%',
        ...Centering,
        justifyContent: 'flex-end',
        paddingBottom: '100px',
        backgroundPositionX: '0%',
        backgroundPositionY: '-40px',
        height: '100vw',
        '@media (max-width: 767px )': {
            marginTop: '10px',
        },
    },
    imgFeaturedContainerMobileRight: {
        width: '100%',
        ...Centering,
        justifyContent: 'flex-end',
        paddingBottom: '100px',
        backgroundPositionX: '100%',
        backgroundPositionY: '-80px',
        height: '100vw',
        '@media (max-width: 767px )': {
            marginTop: '10px',
        },
    },
    imgFeaturedItemMobile: {
        display: 'flex',
    },
    imgMobileTimer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        paddingBottom: 20,
    },
    imgBackgorundDesktop: {
        backgroundColor: '#FFFFF',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        '@media (min-width: 768px )': {
            marginTop: '20px',
            backgroundPositionX: '-15px',
        },
        '@media (min-width: 1024px )': {
            backgroundPositionX: '10px',
        },
        '@media (min-width: 1200px )': {
            backgroundPositionX: '0px',
            backgroundSize: '125%',
            backgroundPositionY: '75%',
        },
    },
    imgBackgorundDesktopTimer: {
        backgroundColor: '#FFFFF',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        '@media (min-width: 768px )': {
            marginTop: '20px',
            backgroundPositionX: '-25px',
        },
        '@media (min-width: 1024px )': {
            backgroundPositionX: '-15px',
        },
        '@media (min-width: 1200px )': {
            backgroundPositionX: '0px',
        },
    },
    imgBackgorundDesktopTimerRight: {
        backgroundColor: '#FFFFF',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        '@media (min-width: 768px )': {
            marginTop: '20px',
            backgroundPositionX: 'calc(100% + 30px)',
        },
        '@media (min-width: 1024px )': {
            marginTop: '20px',
            backgroundPositionX: 'calc(100% + 30px)',
        },
        '@media (min-width: 1200px )': {
            backgroundPositionX: 'calc(100%)',
        },
    },
    imgBackgorundDesktopRight: {
        backgroundColor: '#FFFFF',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        backgroundPositionX: '100%',
        '@media (min-width: 768px )': {
            marginTop: '20px',
            backgroundPositionX: 'calc(100% + 15px)',
        },
        '@media (min-width: 1024px )': {
            marginTop: '20px',
            backgroundPositionX: 'calc(100%)',
        },
        '@media (min-width: 1200px )': {
            backgroundSize: '125%',
            backgroundPositionY: '75%',
        },
    },
    skeletonDesktop: {
        display: 'flex',
        justifyContent: 'center',
    },
    buttonViewAllHome: {
        margin: 'auto',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: `2px solid ${BLUE_LIGHT} !important`,
        borderRadius: '20px',
        height: 26,
        fontSize: 12,
        color: BLUE_LIGHT,
    },
    containerBox: {
        width: '100%',
        marginBottom: 26,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 16,
        },
    },
    additionalMargin56: {
        marginBottom: 26,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 16,
        },
    },
    additionalMargin85: {
        marginBottom: 26,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 16,
        },
    },
    containerBoxBanner: {
        width: '100%',
        background: 'unset',
        backgroundPositionY: -40,
        marginBottom: 54,

    },
    contentBBM: {
        transition: '0.3s',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        [theme.breakpoints.up('sm')]: {
            padding: 8,
        },
    },
    imgBBMItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        backgroundColor: WHITE,
        height: '100%',
    },
    imgBBM: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            width: '90% !important',
        },
    },
    backgroundGrey: {
        backgroundColor: '#F5F4F5',
        paddingBottom: '2.5%',
        marginTop: '50px',
    },
    containerBoxGrey: {
        width: '100%',
        margin: '0 auto',
        marginTop: '50px',
        marginBottom: '50px',
        paddingBottom: '30px',
        backgroundColor: '#F5F4F5',
        [theme.breakpoints.up('lg')]: {
            width: '100%',
        },
        '@media (max-width: 767px )': {
            marginTop: '20px',
            marginBottom: '20px',
        },
    },
    mobileHeader: {
        backgroundColor: 'white',
        marginBottom: 16,
        '@media (min-width: 768px)': {
            display: 'none',
        },
    },
    mobileShoppingAtContainer: {
        backgroundColor: GRAY_100,
        boxShadow: `0px 1px 0px ${GRAY_200}`,
    },
    productCardSlide: {
        width: 148,
        height: 308,
    },
    productCardSlideImg: {
        '@media (max-width: 767px)': {
            'img&': {
                height: 148,
            },
        },
    },
    widgetTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px',
    },
    allCategoryLink: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& span': {
            fontWeight: 'bold',
            fontSize: '14px',
        },
    },
    titleWidget: {
        fontSize: 28,
        fontWeight: 600,
        margin: 0,
        width: '100%',
    },
    linkIcon: {
        fontSize: 18,
    },
}));

export default useStyles;
