import React from 'react';
import { formatPrice } from '@helper_currency';
import { useTranslation } from '@i18n';
import useStyles from './style';

const AdditionalDiscountLabel = (props) => {
    const styles = useStyles();
    const {
        vendor_product_data,
    } = props;
    const { t } = useTranslation(['common']);
    const vendor = vendor_product_data[0] ?? vendor_product_data;
    const vendorProductDataFlashDiscount = vendor?.flash_sale_percent;
    const vendorProductDataFlashAmount = vendor?.flash_sale_discount_amount;
    const vendorFlashSaleFrom = vendor?.flash_sale_from;
    const vendorFlashSaleTo = vendor?.flash_sale_to;
    const nowTime = new Date(Date.now()).getTime();
    const vendorCost = vendor?.vendor_cost;
    const vendorFlashSalePrice = vendor?.flash_sale_price;

    if (
        (vendorProductDataFlashDiscount || vendorProductDataFlashAmount)
        && nowTime >= new Date(vendorFlashSaleFrom).getTime()
        && nowTime <= new Date(vendorFlashSaleTo).getTime()
    ) {
        return (
            <div className={styles.additionalDiscount}>
                <span className={styles.additionalDiscountValue}>
                    {t('common:additionalDiscount')}
                    {' '}
                    {formatPrice(Number(vendorCost - vendorFlashSalePrice), 'IDR')}
                </span>
            </div>
        );
    }
    return null;
};

export default AdditionalDiscountLabel;
