import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    additionalDiscount: {
        color: 'white',
        background: '#D72C0D',
        borderRadius: '5px',
        padding: '0px 6px',
        marginTop: '5px',
        marginBottom: '5px',
        alignSelf: 'start',
    },
    additionalDiscountValue: {
        width: 'auto !important',
        fontSize: '10px',
        color: 'white',
        fontWeight: 'bold',
    },
}));

export default useStyles;
